<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.username" placeholder="手机号" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filters.nickname" placeholder="姓名" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filters.studentId" placeholder="学号" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="filters.certNo" placeholder="身份证" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="list"> 查询 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd"> 新增 </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div ref="mainContent" :style="{ height: mainHeight }">
      <el-table
        v-loading="listLoading"
        height="100%"
        :data="results"
        highlight-current-row
        stripe
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-descriptions border direction="vertical" style="margin: 0 10px">
              <el-descriptions-item v-if="scope.row.roles" label="角色">
                <div v-if="scope.row.roles && scope.row.roles.length">
                  <div>
                    <span>角色：</span>
                    <el-tag
                      v-for="role in scope.row.roles"
                      :key="role"
                      size="mini"
                      style="margin-right: 3px"
                    >
                      {{ role }}
                    </el-tag>
                  </div>
                </div>
                <div v-else>-</div>
              </el-descriptions-item>
              <el-descriptions-item label="姓名">
                {{ scope.row.student.fullName || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="身份证">
                {{ scope.row.identity.certNo || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="学号">
                {{ scope.row.identity.studentId || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="学校">
                {{ scope.row.school.schoolName || '-' }}
              </el-descriptions-item>
              <el-descriptions-item label="是否新生">
                {{ scope.row.student.isNewStudent ? '新生' : '非新生' }}
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="编号" width="100" />
        <el-table-column prop="avatar" label="头像" width="70">
          <template slot-scope="scope">
            <avatar :user="scope.row" size="40" />
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="昵称/姓名" />
        <el-table-column prop="username" label="用户名/手机" />
        <el-table-column prop="createdAt" label="注册时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.createdAt | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="updatedAt" label="更新时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.updatedAt | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <template #empty>
          <el-empty />
        </template>
      </el-table>
    </div>
    <div ref="pagebar" class="pagebar">
      <el-pagination
        :page-sizes="[20, 50, 100, 300]"
        :current-page="page.page"
        :page-size="page.limit"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageChange"
        @size-change="handleLimitChange"
      />
    </div>

    <el-dialog
      :visible.sync="addFormVisible"
      :close-on-click-modal="false"
      title="新增"
    >
      <el-form
        ref="addForm"
        :model="addForm"
        :rules="formRules"
        label-width="120px"
      >
        <el-form-item label="用户名" prop="telephone">
          <el-input v-model="addForm.telephone" placeholder="请输入手机号" />
        </el-form-item>

        <el-form-item label="姓名" prop="fullName">
          <el-input v-model="addForm.fullName" placeholder="请输入姓名" />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" placeholder="请输入密码" />
        </el-form-item>

        <el-form-item label="学号" prop="studentId">
          <el-input v-model="addForm.studentId" placeholder="请输入学号" />
        </el-form-item>
        <el-form-item label="身份证" prop="certNo">
          <el-input v-model="addForm.certNo" placeholder="请输入身份证" />
        </el-form-item>
        <el-form-item label="学校" prop="schoolId">
          <el-select
            v-model="addForm.schoolId"
            placeholder="请选择学校"
            filterable
          >
            <el-option
              v-for="item in school"
              :key="item.id"
              :label="item.schoolName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否新生" prop="isNewStudent">
          <el-select v-model="addForm.isNewStudent" placeholder="请选择">
            <el-option :value="true" label="是" />
            <el-option :value="false" label="否" />
          </el-select>
        </el-form-item>
        <el-form-item label="预计毕业时间">
          <el-date-picker
            v-model="addForm.expectGraduationDate"
            value-format="timestamp"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false"> 取消 </el-button>
        <el-button
          :loading="addLoading"
          type="primary"
          @click.native="addSubmit"
        >
          提交
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
      title="编辑"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        :rules="formRules"
        label-width="120px"
      >
        <el-input v-model="editForm.id" type="hidden" />
        <el-form-item label="用户名" prop="telephone">
          <el-input v-model="editForm.telephone" placeholder="请输入手机号" />
        </el-form-item>

        <el-form-item label="姓名" prop="fullName">
          <el-input v-model="editForm.fullName" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="学号" prop="studentId">
          <el-input v-model="editForm.studentId" placeholder="请输入学号" />
        </el-form-item>
        <el-form-item label="身份证" prop="certNo">
          <el-input v-model="editForm.certNo" placeholder="请输入身份证" />
        </el-form-item>
        <el-form-item label="学校" prop="schoolId">
          <el-select
            v-model="editForm.schoolId"
            filterable
            placeholder="请选择学校"
            @change="handleSchoolChange"
          >
            <el-option
              v-for="item in school"
              :key="item.id"
              :label="item.schoolName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-select
            v-model="editForm.roles"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="用户角色"
            style="width: 100%"
          >
            <el-option
              v-for="item in editForm.roles"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否新生" prop="isNewStudent">
          <el-select v-model="editForm.isNewStudent" placeholder="请选择">
            <el-option :value="true" label="是" />
            <el-option :value="false" label="否" />
          </el-select>
        </el-form-item>
        <el-form-item label="预计毕业时间">
          <el-date-picker
            v-model="editForm.expectGraduationDate"
            value-format="timestamp"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="editForm.password"
            placeholder="不填写表示不更改密码"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false"> 取消 </el-button>
        <el-button
          :loading="editLoading"
          type="primary"
          @click.native="editSubmit"
        >
          提交
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import Avatar from '@/components/Avatar'
import mainHeight from '@/utils/mainHeight'

export default {
  name: 'User',
  components: { Avatar },
  data() {
    return {
      mainHeight: '300px',
      results: [],
      school: [],
      listLoading: false,
      page: {},
      filters: {},
      selectedRows: [],
      addForm: {
        telephone: '',
        fullName: '',
        avatar: '',
        password: '',
        studentId: '',
        schoolId: '',
        certNo: ''
      },
      addFormVisible: false,
      formRules: {
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change'
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]+\S{5,12}$/,
            message: '需同时含有字母和数字，长度在6-12之间',
            trigger: 'change'
          }
        ],
        fullName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 5,
            message: '姓名长度在2到5个字符',
            trigger: 'change'
          },
          {
            pastern: /^[\u4e00-\u9fa5]+$/,
            message: '姓名必须为中文',
            trigger: 'change'
          }
        ],
        studentId: [
          {
            required: true,
            message: '请输入学号',
            trigger: 'change'
          }
        ],
        certNo: [
          {
            required: true,
            message: '请输入身份证号码',
            trigger: 'change'
          },
          {
            pattern:
              /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|10|11|12)([0-2][1-9]|10|20|30|31)\d{3}[\dXx]$/,
            message: '请输入正确的身份证号码',
            trigger: 'change'
          }
        ],
        schoolId: [
          {
            required: true,
            message: '请输入学校',
            trigger: 'change'
          }
        ],
        telephone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'change'
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号码格式不正确',
            trigger: 'blur'
          }
        ]
      },
      addLoading: false,
      editForm: {
        id: '',
        sid: '',
        iid: '',
        roles: [],
        telephone: '',
        fullName: '',
        avatar: '',
        password: '',
        studentId: '',
        schoolId: '',
        schoolName: '',
        certNo: '',
        password: '',
        isNewStudent: false,
        expectGraduationDate: 0
      },
      editFormVisible: false,
      editLoading: false
    }
  },
  mounted() {
    mainHeight(this)
    this.schoolList()
    this.list()
  },
  methods: {
    schoolList() {
      this.axios.form('/api/school/list').then((data) => {
        this.school = data
      })
    },
    list() {
      const me = this
      me.listLoading = true
      const params = Object.assign(me.filters, {
        page: me.page.page,
        limit: me.page.limit
      })
      this.axios
        .form('/api/admin/user/list', params)
        .then((data) => {
          me.results = data.results
          me.page = data.page
        })
        .finally(() => {
          me.listLoading = false
        })
    },
    handleSchoolChange(value) {
      // this.editForm.schoolId = value
      this.$forceUpdate()
    },
    handlePageChange(val) {
      this.page.page = val
      this.list()
    },
    handleLimitChange(val) {
      this.page.limit = val
      this.list()
    },
    handleAdd() {
      this.addForm = {
        telephone: '',
        fullName: '',
        avatar: '',
        password: '',
        studentId: '',
        schoolId: '',
        certNo: ''
      }
      this.addFormVisible = true
    },
    addSubmit() {
      const me = this
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          this.axios
            .form('/api/admin/user/create', this.addForm)
            .then((data) => {
              me.$message({ message: '提交成功', type: 'success' })
              me.addFormVisible = false
              me.list()
            })
            .catch((rsp) => {
              me.$notify.error({ title: '错误', message: rsp.message })
            })
        } else {
          return false
        }
      })
    },
    handleEdit(row) {
      if (row.id === 1) {
        this.$notify.warning({
          title: '提示',
          message: '超级管理员信息不可以修改'
        })
        return
      }
      this.editFormVisible = true
      this.editForm = {
        id: row.id,
        sid: row.student.id,
        iid: row.identity.id,
        telephone: row.username,
        fullName: row.student.fullName,
        studentId: row.student.studentId,
        certNo: row.identity.certNo,
        schoolId: row.student.schoolId,
        expectGraduationDate: row.student.expectGraduationDate,
        isNewStudent: row.student.isNewStudent
      }
    },
    editSubmit() {
      const params = { ...this.editForm }
      if (params.roles && params.roles.length) {
        params.roles = params.roles.join(',')
      } else {
        params.roles = ''
      }
      const me = this
      this.axios
        .form('/api/admin/user/update', params)
        .then((data) => {
          me.$message({ message: '修改成功', type: 'success' })
          me.list()
          me.editFormVisible = false
        })
        .catch((rsp) => {
          me.$notify.error({ title: '错误', message: rsp.message })
        })
    },
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    handleCommand(cmd) {
      if (cmd.cmd === 'edit') {
        this.handleEdit(cmd.row)
      } else if (cmd.cmd === 'examine') {
        this.removeForbidden(cmd.row)
      } else if (cmd.cmd === 'notExamine') {
        this.showForbiddenDialog(cmd.row)
      }
    }
  }
}
</script>

<style scoped></style>
